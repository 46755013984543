import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['content', 'stepContent', 'nextButton']
  static values = {
    show: Boolean,
    currentStep: Number,
    loggedIn: Boolean
  }

  connect() {
    this.totalSteps = 3
    this.currentStepValue = 1
    
    if (this.showValue && this.shouldShowTutorial()) {
      this.openModal()
    }
  }

  shouldShowTutorial() {
    if (this.loggedInValue) {
      return true
    }
    
    const tutorialSeen = localStorage.getItem('tutorialSeen')
    return tutorialSeen !== 'true'
  }

  openModal() {
    const template = document.getElementById('tutorialModalTemplate')
    this.contentTarget.innerHTML = template.innerHTML
    this.updateStepContent()
  }

  updateStepContent() {
    const template = document.getElementById(`step${this.currentStepValue}Template`)
    this.stepContentTarget.innerHTML = template.innerHTML
        
    // 根據當前步驟更新按鈕文字
    const nextButton = this.nextButtonTarget
    if (this.currentStepValue === 1) {
      nextButton.textContent = nextButton.dataset.startText
    } else if (this.currentStepValue === this.totalSteps) {
      nextButton.textContent = nextButton.dataset.doneText
    } else {
      nextButton.textContent = nextButton.dataset.nextText
    }
  }

  nextStep() {
    if (this.currentStepValue === this.totalSteps) {
      this.closeModal()
      return
    }
    
    this.currentStepValue++
    this.updateStepContent()
  }

  closeModal() {
    this.contentTarget.innerHTML = ''

    if (this.loggedInValue) {      
      this.readTutorial()
    }
  }

  readTutorial() {
    const csrfToken = document.querySelector('meta[name="csrf-token"]')?.content

    fetch('/api/v1/profile/read_tutorial', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      credentials: 'same-origin'
    })
    .then(response => {
      if (response.status === 401) {
        throw new Error('Unauthorized')
      }

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json()
    })
    .catch(error => {
      console.error('Failed to read tutorial:', error)
    })
  }
}