import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "creatorButton", "userButton", "primaryNav", "secondaryNav" ]

  showPrimary(event) {
    if (event.currentTarget.classList.contains("active")) {
      return;
    }
    window.location.href = "/profile/models";
  }

  showSecondary(event) {
    if (event.currentTarget.classList.contains("active")) {
      return;
    }
    window.location.href = "/profile/libraries";
  }
}

