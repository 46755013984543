import i18next from 'i18next';

import en from 'en.yml';
import zhTW from 'zh-TW.yml';
import jp from 'jp.yml';
import zhCN from 'zh-CN.yml';
import test from 'test.yml';

export const initI18n = () => {
  const defaultLocale = window.global_values.LOCALE || 'en';
  const supportedLngs = window.global_values.AVAILABLE_LOCALES || ['en'];
  i18next.init({
    lng: defaultLocale,
    resources: { 
      en: { translation: en.en },
      'zh-TW': { translation: zhTW['zh-TW'] },
      jp: { translation: jp.jp },
      'zh-CN': { translation: zhCN['zh-CN'] },
      test: { translation: test.test }
    },
    fallbackLng: 'en',
    supportedLngs,
    interpolation: {
      prefix: '%{',
      suffix: '}'
    },
  },(err, t) => {
    if (!err) {
      window.i18nextGlobal = t;
    }
  });
}
