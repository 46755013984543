import { Controller } from "stimulus"
import { subMonths, format, addDays, subDays, parse} from 'date-fns'
import i18next from 'i18next'

export default class extends Controller {
  static targets = ['chart', 'start', 'end', 'type', 'search','range','individualInput' ,'individualLabel']
  static values = {}

  chartModule = null

  // input options
  inputOptions = {
    'post_views': ['total_count'],
    'revenue': ['total_price'],
    'like_counts': ['total_count'],
    'download_counts': ['total_count'],
    'follow_counts': ['total_count']
  }
  
  // chart settings
  chartConfig = {
    datasets: {
      // line
      borderColor: 'rgba(255, 159, 64, 1)',
    },
    options: {
      maintainAspectRatio: false,
      scales: {
        y: {
          grid: {
            borderColor: 'white',
            color: 'grey'
          },
          ticks: {
            min: 10,
            beginAtZero: true,
            color: 'white',
            borderColor: 'white',
            callback: function(value, index, values) {
              // 避免小數
              if (Math.floor(value) === value) {
                return value;
              }
            }
          }
        },
        x: {
          grid: {
            borderColor: 'white',
            color: 'grey',
            borderDash: [10, 10]
          },
          ticks: {
            color: 'white',
            autoSkip: true,
            maxTicksLimit: 5,
            maxRotation: 0,
            minRotation: 0
          }
        },
        y: {
          suggestedMin: 0,
          suggestedMax: 30,
        }
      },
      plugins:{
        legend: {
          display: true,
          position: "bottom",
          align:"start",
          labels:{
            usePointStyle: true,
            pointStyle: "circle",
            color: '#99A0A8',
            boxWidth: 5,
            boxHeight: 5,
          },
        },
        tooltip: {
          titleAlign: "center",
          usePointStyle: true,
          padding: 10,
          bodySpacing: 8,
          boxWidth: 5,
          boxHeight: 5,
          boxPadding: 5,
          callbacks: {
                label: function(context) {
                    // 根據context中的資料自定義標籤
                    let label = context.dataset.label || '';
                    let value = context.parsed.y !== null ? context.parsed.y : '';
                    if (label === i18next.t('analytics_controller.revenues')) {
                      return `${label}: $${value} `; 
                  } else {
                      return `${label}: ${value}`; // 其他數據點不添加單位
                  }
                }
            }
        }
      },
      interaction: {
        mode: 'index'
      }
    }
  }

  chartParams = {
    start: format(subMonths(new Date(), 1), 'yyyy/MM/dd'),
    end: format(new Date(), 'yyyy/MM/dd'),
    previousStart: format(subMonths(new Date(), 2), 'yyyy/MM/dd'),
    previousEnd: format(subMonths(new Date(), 1), 'yyyy/MM/dd'),
    source: 'all_data',
    type: 'all_data',
    postIds: [],
    range: ""
  }

  chartData = {}
  
  colorMapping = {
    'post_views': '#DC49D6',
    'revenue': '#2BDEE9',
    'like_counts': '#FFCB68',
    'download_counts': '#29CB00',
    'follow_counts': 'rgba(153, 102, 255, 1)'
  }
 
  // individual model sort 
  sortDirection = {}

  connect() {
    // init input date
    this.startTarget.value = this.chartParams.start.replace(/\//g, '-')
    this.endTarget.value = this.chartParams.end.replace(/\//g, '-')

    this.initializeChartSetup().then(() => {
      this.initializeChartData();
      this.compareAllPostData(
        this.chartParams.start,
        format(addDays(parse(this.chartParams.end, 'yyyy/MM/dd', new Date()), 1), 'yyyy/MM/dd'),
        this.chartParams.previousStart,
        format(addDays(parse(this.chartParams.previousEnd, 'yyyy/MM/dd', new Date()), 1), 'yyyy/MM/dd')
      ).then(()=>{
        this.initializeEvents()
        const dateEndTip = document.getElementById('date-end-tip')
        dateEndTip.innerHTML = format(parse(this.chartParams.previousEnd, 'yyyy/MM/dd', new Date()), 'MMMM dd, yyyy')
      });
    });
    
  }
  
  async importPackage() {
    this.chartModule = await import("chart.js")
  }

  async initializeChartSetup(){
    await this.importPackage();
    this.initializeChart();
    this.adjustForPrint();
  }

  initializeChart() {
    this.chartModule.Chart.register(...this.chartModule.registerables);
    this.chart = new this.chartModule.Chart(this.chartTarget, {
      type: 'line',
      data: {
        labels: [],
        datasets: [{
          label: this.chartParams.type,
          data: [],
          ...this.chartConfig.datasets
        }]
      },
      options: {
        ...this.chartConfig.options,
        // onClick: this.barClickHandeler
      }
    });
  }

  adjustForPrint() {
    window.addEventListener('afterprint', () => {
      this.chart.resize();
    });
  }

  async initializeChartData() {
    await this.getAllChartData(
      this.chartParams.start,
      format(addDays(parse(this.chartParams.end, 'yyyy/MM/dd', new Date()), 1), 'yyyy/MM/dd'),
      this.chartParams.postIds
    );
    this.toAllChartData(this.chartParams.start, this.chartParams.end);
  }
  

  initializeEvents() {
      this.addSortEvents();
      this.addSyncScrollEvents();
      this.initializeDateLimitControls();
  }

  // add individualPosts sort event
  addSortEvents() {
    const sortTrianglesLists = document.querySelectorAll('.sort-triangle')
    sortTrianglesLists.forEach((triangle, index) => {
      triangle.addEventListener('click', (e) => {
        const criteria = e.target.parentNode.textContent.trim();
        this.sortPostsBy(criteria)
        this.changeSortBtnColor(e.target, criteria, sortTrianglesLists)
      })
    })
  }

  // add individualPosts title & Container scroll event
  addSyncScrollEvents() {
    const individualPostsTitle = document.querySelector('.individual_title');
    const individualPostsContainer = document.querySelector('.individual-posts-container');
    individualPostsTitle.addEventListener('scroll', function() {
      individualPostsContainer.scrollLeft = this.scrollLeft;
    });
    individualPostsContainer.addEventListener('scroll', function() {
      individualPostsTitle.scrollLeft = this.scrollLeft;
    });
  }

  initializeDateLimitControls() {
    this.updateDateLimits()
    this.startTarget.addEventListener('change', () => this.updateDateLimits())
    this.endTarget.addEventListener('change', () => this.updateDateLimits())
  }

  inputHandler(e) {
    const value = e.target.value
    const name = e.target.name
    switch(name) {
      case 'date-start':
        this.chartParams.start = format(parse(value, 'yyyy-MM-dd', new Date()), 'yyyy/MM/dd')
        break
      case 'date-end':
        this.chartParams.end = format(parse(value, 'yyyy-MM-dd', new Date()), 'yyyy/MM/dd')
        break
      case 'post':
        this.chartParams.postIds = [value]
        this.renderNewChart()
        break
      case 'source':
        this.chartParams.source = value
        if( value !== "all_data"){
          this.chartParams.type = this.inputOptions[this.chartParams.source][0]
        }
        // for (let i = 0; i < this.typeTarget.length; i++) {
        //   if (!this.inputOptions[this.chartParams.source].includes(this.typeTarget.options[i].value)) {
        //     this.typeTarget.options[i].disabled = true
        //   } else {
        //     this.typeTarget.options[i].disabled = false
        //   }
        // }
        break
      default:
        console.log('input type err')
        break
    }
  }

  updateDateRange(e){
    const value = Number(e.target.value)
    const endDate = new Date(this.endTarget.value);
    const newStartDate = new Date(endDate.setDate(endDate.getDate() - value)).toISOString().split('T')[0];
    this.startTarget.value = newStartDate
    this.chartParams.start = format(parse(newStartDate, 'yyyy-MM-dd', new Date()), 'yyyy/MM/dd')
    this.updateDateLimits()  
  }

  calculatePreviousDateRange(){
    const startDate = new Date(this.chartParams.start)
    const endDate = new Date(this.chartParams.end)
    const differenceInDays = ((endDate - startDate) / (1000 * 60 * 60 * 24)).toFixed(0)

    this.chartParams.previousStart = format(subDays(startDate, differenceInDays),'yyyy/MM/dd');
    this.chartParams.previousEnd =  format(subDays(endDate, differenceInDays),'yyyy/MM/dd');
    this.updateDateTip()
  }

  updateDateTip(){
    const dateStartTip = document.getElementById('date-start-tip')
    const dateEndTip = document.getElementById('date-end-tip')
    dateStartTip.innerHTML = format(new Date(this.chartParams.previousStart), 'MMMM dd, yyyy')
    dateEndTip.innerHTML = format(parse(this.chartParams.previousEnd, 'yyyy/MM/dd', new Date()), 'MMMM dd, yyyy')
  }

  calculateDateLimits(date, isAdding) {
    const result = new Date(date)
    result.setDate(result.getDate() + (isAdding ? 182 : -182))
    return result.toISOString().split('T')[0]
  }

  updateDateLimits() {
    if (this.startTarget.value) {
        this.endTarget.setAttribute('max', this.calculateDateLimits(this.startTarget.value, true));
    }
    if (this.endTarget.value) {
        this.startTarget.setAttribute('min', this.calculateDateLimits(this.endTarget.value, false));
    }
  }

  typeHandler(e) {
    const value = e.target.value
    this.chartParams.type = value
  }

  search(e) {
    this.calculatePreviousDateRange()
    this.compareAllPostData(this.chartParams.start, format(addDays(parse(this.chartParams.end, 'yyyy/MM/dd', new Date()), 1), 'yyyy/MM/dd'), this.chartParams.previousStart, this.chartParams.previousEnd)
    if(this.chartParams.source === "all_data"){
      this.getAllChartData(this.chartParams.start, 
        this.chartParams.end,
        this.chartParams.postIds)
      .then(() => {
        this.toAllChartData(this.chartParams.start, this.chartParams.end)
      })
    } else {
      
      this.getChartData(this.chartParams.start, this.chartParams.end, this.chartParams.postIds, this.chartParams.source)
        .then(() => {
          this.toChartData(this.chartParams.start, this.chartParams.end, this.chartParams.type, this.chartParams.source)
      })
    }
  }

  renderNewChart(){
    if(this.chartParams.source === "all_data"){
      this.getAllChartData(this.chartParams.start, 
        this.chartParams.end,
        this.chartParams.postIds)
      .then(() => {
        this.toAllChartData(this.chartParams.start, this.chartParams.end)
      })
    } else {
      
      this.getChartData(this.chartParams.start, this.chartParams.end, this.chartParams.postIds, this.chartParams.source)
        .then(() => {
          this.toChartData(this.chartParams.start, this.chartParams.end, this.chartParams.type, this.chartParams.source)
      })
    }
  }

  fetchAllPostsData(start, end) { 
    let api_url = `/api/v1/analysis/all_posts?`
    let params = new URLSearchParams({
      start_at: start,
      end_at: end,
    })
    return fetch(api_url + params)
      .then(res => res.json())
      .then(json => {
        return json
      })
      .catch(err => console.log(err))
  }

  compareAllPostData(currentStart, currentEnd, previousStart, previousEnd) {
    return new Promise((resolve, reject) => {
      // current period data
      this.fetchAllPostsData(currentStart, currentEnd)
        .then(currentData => {
          if(!currentData.all_posts_data){
            this.drawNoModelsSummary()
            this.drawNoModelsIndividualSummary()
            return
          }
        // previous period data
          this.fetchAllPostsData(previousStart, previousEnd)
            .then(previousData => {
              const growth = {
                views: (((currentData.all_posts_data.views - previousData.all_posts_data.views) / previousData.all_posts_data.views) * 100).toFixed(1),
                revenues: (((currentData.all_posts_data.revenues - previousData.all_posts_data.revenues) / previousData.all_posts_data.revenues) * 100).toFixed(1),
                likes: (((currentData.all_posts_data.likes - previousData.all_posts_data.likes) / previousData.all_posts_data.likes) * 100).toFixed(1),
                download: (((currentData.all_posts_data.download - previousData.all_posts_data.download) / previousData.all_posts_data.download) * 100).toFixed(1),
                saved: (((currentData.all_posts_data.saved - previousData.all_posts_data.saved) / previousData.all_posts_data.saved) * 100).toFixed(1),
              };
              // base on data update UI
              this.updateAllModelsSummary(currentData.all_posts_data, growth)
              this.updateIndividualModelsSummary(currentData.Individual_post_data)
              this.updateDateTip()
              resolve(growth); 
            })
            .catch(err => {
              console.log('Error fetching previous data:', err);
              reject(err); 
            });
        })
        .catch(err => {
          console.log('Error fetching previous data:', err);
          reject(err); 
        });
    })
  }
  
  getChartData(start, end, postIds, source) { 
    let api_url = `/api/v1/analysis/${source}?`
    let params = new URLSearchParams({
      start_at: start,
      end_at: end,
    })
    if(postIds.length > 0) {
      postIds.forEach(id => {
        params.append('post_ids[]', id)
      })
    }
    return fetch(api_url + params)
      .then(res => res.json())
      .then(json => json.data)
      .then(data => {this.chartData[source] = data})
      .catch(err => console.log(err))
  }
  
  getAllChartData(start, end, postIds) {
    const sources = ['post_views', 'revenue', 'like_counts', 'download_counts'];
    return Promise.all(sources.map(source => 
        this.getChartData(start, end, postIds, source)
    ));
  }

  toChartData(start, end, type, source) {
    const dateList = this.getDateRange(start, end)
    const labels = dateList
    const datasets = this.getTotalList(dateList, this.chartData[source], type)
    this.update_chart({labels, datasets})
  }

  toAllChartData(start, end) {
    const dateList = this.getDateRange(start, end)
    const labels = dateList;
    const datasetsList = {}
    Object.entries(this.chartData).forEach( chartItem => {
      const type = chartItem[0] === "revenue" ? "total_price" : "total_count";
      const datasets = this.getTotalList(dateList, chartItem[1], type)
      datasetsList[chartItem[0]] = datasets
    })
    this.update_all_chart({labels, datasetsList})
  }

  totalViewByPost(date) {
    let labels = []
    let datasets = []
    let chartItems = this.chartData[date]
    const result = chartItems.forEach((item) => {
      labels.push(item.post_title.slice(0, 10))
      datasets.push(item.total_count)
    })
    this.update_chart({labels, datasets})
  }

  update_chart(chartItems) {
    // reset
    this.chart.data.labels = []
    this.chart.data.labels = chartItems.labels
    this.chart.data.datasets = []
    const chart = {
      label: this.chartParams.source,
      data: chartItems.datasets,
      ...this.chartConfig.datasets
    }
    chart.borderColor = this.colorMapping[this.chartParams.source]
    chart.backgroundColor = this.colorMapping[this.chartParams.source]
    this.updateLegendLabels(chart)
    this.chart.data.datasets.push(chart)
    this.chart.update('active')
  }

  update_all_chart(chartItems) {
    // reset
    this.chart.data.labels = []
    this.chart.data.labels = chartItems.labels
    this.chart.data.datasets = []
    Object.entries(chartItems.datasetsList).forEach(chartItem =>{
      const chart = {
        label: chartItem[0],
        data: chartItem[1],
        ...this.chartConfig.datasets
      }
      this.updateLegendLabels(chart)
      chart.borderColor = this.colorMapping[chartItem[0]]
      chart.backgroundColor = this.colorMapping[chartItem[0]]
      
      this.chart.data.datasets.push(chart)
    })
    this.chart.update('active')
  }

  getDateRange(start, end) {
    const dateStart = new Date(start)
    const dateEnd = new Date(end)
    let arr = []
    for(dateStart; dateStart <= dateEnd; dateStart.setDate(dateStart.getDate() + 1)){
      arr.push(format(new Date(dateStart), 'yyyy/MM/dd'))
    }
    return arr
  }

  getTotalList(labels, chartItems, type) {
    return labels.map((date) => {
      if(chartItems[date]) {
        return chartItems[date]
                .map((item) => item[type])
                .reduce((acc, current) => acc + parseFloat(current), 0)
      } else {
        return 0
      }
    })
  }

  barClickHandeler(evt, el, chart) {
    if(el.length) {
      console.log('Get the underlying label for click,', evt, el, this.chartParams.type)
    }
  }

  updateLegendLabels(chart){
    switch(chart.label) {
      case 'post_views':
        chart.label = i18next.t('analytics_controller.views')
        break
      case 'like_counts':
        chart.label = i18next.t('analytics_controller.likes')
        break
      case 'download_counts':
        chart.label = i18next.t('analytics_controller.download')
        break
      case 'revenue':
        chart.label = i18next.t('analytics_controller.revenues')
        break
    }
  }

  updateAllModelsSummary(allModelsData, growth){
    let allModelsContainer = document.querySelector('.all-models-container')
    allModelsContainer.innerHTML = ""
    let htmlContent = "";
    Object.keys(allModelsData).forEach((key, index) =>{
      const colSpanClass = index < 2 ? 'col-span-3' : 'col-span-2';
      if(allModelsData[key] >= 1000){
        allModelsData[key] = (allModelsData[key]/1000).toFixed(1) + "K"
      }

      let growthRate = growth[key] > 0 ? 'increase' : (growth[key] < 0 ? 'decline' : 'steady');
      if (isNaN(growth[key])) {
        growth[key] = 0 + '%';
      } else if (!isFinite(growth[key])) {
        growth[key] = '';
        growthRate = 'infinity'
      } else {
        growth[key] = growth[key] + '%';
      }

      let summaryKey = i18next.t(`analytics_controller.${key}`);
      if(summaryKey === i18next.t('analytics_controller.revenues')){
        allModelsData[key] = '$' + Number(allModelsData[key]).toFixed(2);
      }

      htmlContent += `
        <div class=" bg-grey-1 flex-col py-2 px-3 my-2 rounded-lg ${colSpanClass}">
          <div class="text-14px">${summaryKey}</div>
          <div class="flex justify-between font-bold">
            <div class="text-32px">${allModelsData[key]}</div>
            <div class="flex items-center text-20px relative ${growthRate} ">
              <span class="inline-block icon w-5 h-5"></span>
              <span class="rate-change">${growth[key]} </span>
            </div>
           </div>
        </div>
      `
    });
    allModelsContainer.innerHTML = htmlContent;
    this.addInfinityHint()
    return {allModelsData, growth}
  }

  drawNoModelsSummary(){
    let allModelsContainer = document.querySelector('.all-models-container')
    allModelsContainer.innerHTML = ""
    let htmlContent = "";
    let allModelsData = {
      'views': 0 ,
      'revenues': 0 ,
      'likes': 0 ,
      'download': 0 ,
      'saved': 0 ,
    }
    Object.keys(allModelsData).forEach((key, index) =>{
      const colSpanClass = index < 2 ? 'col-span-3' : 'col-span-2';

      let summaryKey = i18next.t(`analytics_controller.${key}`);
      if(summaryKey === i18next.t('analytics_controller.revenues')){
        allModelsData[key] = '$' + Number(allModelsData[key]).toFixed(2);
      }

      htmlContent += `
        <div class=" bg-grey-1 flex-col py-2 px-3 my-2 rounded-lg ${colSpanClass}">
          <div class="text-14px">${summaryKey}</div>
          <div class="flex justify-between font-bold">
            <div class="text-32px">${allModelsData[key]}</div>
            <div class="flex items-center text-20px relative ">
              <span class="inline-block icon w-5 h-5"></span>
              <span class="rate-change"></span>
            </div>
           </div>
        </div>
      `
    });
    allModelsContainer.innerHTML = htmlContent;
  }

  drawNoModelsIndividualSummary(){
    let individualPostsContainer = document.querySelector('.individual-posts-container');
    let individualContent = i18next.t('public_profiles.collection_empty.post');
    individualPostsContainer.innerHTML = ""
    let htmlContent = `
    <div class="individual-post-container flex flex-col my-12 justify-center items-center">
      <div class="w-16 h-16 icon analytics-icon"> </div>
      <div class="my-6">${individualContent}</div>
    </div>
    `
    individualPostsContainer.innerHTML = htmlContent;
  }
  

  addInfinityHint(){
    const inInfinityHintsIcons = document.querySelectorAll('.infinity .icon');
    // create inInfinityHintPanel & add click event
    inInfinityHintsIcons.forEach(icon => {
      const inInfinityHintPanel = `
        <div class='nav-dropdown-mask infinity-hint-panel hidden w-64' data-nav-dropdown-target='panel'>
        <div class="nav-dropdown-wrap">
          <div class='nav-dropdown'>
            <div class="text-12px text-center">
              No growth rate if last period value is 0.
            </div>
          </div>
        </div>
      </div>
      `
      icon.insertAdjacentHTML('afterend', inInfinityHintPanel);
      
      // add click event
      icon.addEventListener('click', ()=> {
        const infinityHintPanel = icon.nextElementSibling;
        infinityHintPanel.classList.toggle('hidden');
      });
    });
    
  }

  // update individual post relative event

  updateIndividualModelsSummary(individualModelsData){
    let individualPostsContainer = document.querySelector('.individual-posts-container')
    individualPostsContainer.innerHTML = ""
    let htmlContent = "";
    individualModelsData.sort((a, b) => {
      return b.post_id - a.post_id;
    });
    individualModelsData.forEach((post, index) =>{
      if(post['total_views'] > 1000){
        post['total_views'] = (post['total_views']/1000).toFixed(1) + "K"
      }
      const postLink = post['post_state'] === 'published' ? 
      `/posts/${post['post_id']}` : 
      `/posts/${post['post_id']}/preview`;

      if (index > 0) {
        htmlContent += `
          <hr class='col-span-12 border-grey-3 my-2 individual-post-hr sm:w-auto'>
        `
      }

      htmlContent += `
        <div class="individual-post-container flex flex-col" data-post-id='${post['post_id']}'>
          <div class="individual-post flex items-center bg-grey-2 py-2 text-14px rounded-lg border-grey-3 
                      sm:w-auto sm:w-full sm:grid sm:grid-cols-[7fr_4fr_4fr_4fr_4fr_4fr_4fr]"
              data-post-id = '${post['post_id']}'
          >
            <div class='w-40 flex-shrink-0 sm:w-auto px-2 post-name break-word'>
              <a href='${postLink}'>${post['post_title']}</a>
            </div>
            <div class='w-24 flex-shrink-0 ml-2 sm:ml-0 sm:w-auto px-1 post-revenue'>$${Number(post['total_revenue']).toFixed(2)}</div>
            <div class='w-20 flex-shrink-0 ml-2 sm:ml-0 sm:w-auto px-1 post-like'>${post['total_likes']}</div>
            <div class='w-20 flex-shrink-0 ml-2 sm:ml-0 sm:w-auto px-1 post-view'>${post['total_views']}</div>
            <div class='w-15 flex-shrink-0 ml-2 sm:ml-0 sm:w-auto px-1 post-download'>${post['total_downloads']}</div>
            <div class='w-12 flex-shrink-0 ml-2 sm:ml-0 sm:w-auto px-1 post-save'>${post['save_count']}</div>
            <div class='w-18 flex-shrink-0 ml-2 sm:ml-0 sm:w-auto px-1 post-rating flex'>${post['post_rating'].toFixed(1)} / 5
              <span class="individual-post-collapse-btn inline-block flex justify-center items-center"> 
                <input id='individual-post-${post['post_id']}-input' 
                       class='checkbox-dropdown' 
                       type='checkbox' 
                       data-post-id='${post['post_id']}'
                       data-analytics-target='individualInput'>
                <label for='individual-post-${post['post_id']}-input' 
                       class='w-4 h-4 font-bold text-16px px-4 collapse-btn' 
                       data-post-id='${post['post_id']}'
                       data-analytics-target='individualLabel'
                       data-action='click->analytics#collapseRevenue'></label>
              </span>
            </div>
          </div>
        </div>
      `;
    })
    individualPostsContainer.innerHTML = htmlContent;
  }

  sortPostsBy(sortCriteria){
    if(this.sortDirection[sortCriteria]){
      this.sortDirection[sortCriteria] = this.sortDirection[sortCriteria] === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortDirection = {}
      this.sortDirection[sortCriteria] = 'desc'
    }
    const container = document.querySelector('.individual-posts-container')
    const posts = Array.from(container.querySelectorAll('.individual-post-container'))
  
    posts.sort((a, b) => {
      let valueA, valueB;
      switch (sortCriteria) {
        case i18next.t('private_profiles.analytic.revenues'):
          valueA = parseFloat(a.querySelector('.post-revenue').textContent.replace('$', ''));
          valueB = parseFloat(b.querySelector('.post-revenue').textContent.replace('$', ''));
          break;
        case i18next.t('private_profiles.analytic.likes'):
          valueA = parseInt(a.querySelector('.post-like').textContent);
          valueB = parseInt(b.querySelector('.post-like').textContent);
          break;
        case i18next.t('private_profiles.analytic.views'):
          valueA = this.parseMetricValue(a.querySelector('.post-view').textContent.trim());
          valueB = this.parseMetricValue(b.querySelector('.post-view').textContent.trim());
          break;
        case i18next.t('private_profiles.analytic.download'):
          valueA = parseInt(a.querySelector('.post-download').textContent);
          valueB = parseInt(b.querySelector('.post-download').textContent);
          break;
        case i18next.t('analytics_controller.saved'):
        case i18next.t('private_profiles.analytic.saved'):
          valueA = parseInt(a.querySelector('.post-save').textContent);
          valueB = parseInt(b.querySelector('.post-save').textContent);
          break;
        case i18next.t('private_profiles.analytic.rating'):
          valueA = parseFloat(a.querySelector('.post-rating').textContent);
          valueB = parseFloat(b.querySelector('.post-rating').textContent);
          break;
        default:
          return 0; 
      }
      return (this.sortDirection[sortCriteria] === 'desc') ? valueB - valueA : valueA - valueB;
    });

    container.innerHTML = '';
    posts.forEach(post => {
      container.appendChild(post);
      const hr = document.createElement('hr');
      hr.classList.add('col-span-12', 'border-grey-3', 'my-2');
      container.appendChild(hr);
    });
  }

   parseMetricValue(value) {
    if (value.includes('K')) {
      return parseFloat(value.replace('K', '')) * 1000;
    }
    return parseFloat(value);
  }

  changeSortBtnColor(target, sortCriteria, sortTrianglesLists){
    sortTrianglesLists.forEach(triangle => {
      triangle.classList.remove('desc', 'asc')
    })
    target.classList.add(this.sortDirection[sortCriteria] === 'asc' ? 'asc' : 'desc');
  }

  collapseRevenue(e){
    const label = e.target
    const postId = e.target.dataset.postId
    const checkbox = this.individualInputTargets.find(input => {
      return input.dataset.postId === postId;
    });
    const targetContainer = document.querySelector(`.individual-post-container[data-post-id='${postId}']`);
    const revenuesDivs = targetContainer.querySelectorAll('.individual-post-revenues') || '';
    requestAnimationFrame(() => {
      if (checkbox.checked) {
        if (revenuesDivs.length === 0) {
          this.fetchIndividualPostRevenuesDetails(this.chartParams.start, this.chartParams.end, postId)
            .then(data => {
              data['individual_post_revenues'].forEach(variant => {
                const pv_tag = variant['phrozen_verified'] ? `
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect width="20" height="20" rx="5" fill="#E60044"/>
                  <path d="M9.99989 12.5769C12.2303 12.5769 14.0384 10.7689 14.0384 8.53847C14.0384 6.30808 12.2303 4.5 9.99989 4.5C7.76951 4.5 5.96143 6.30808 5.96143 8.53847C5.96143 10.7689 7.76951 12.5769 9.99989 12.5769Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10.0002 10.1536C10.8923 10.1536 11.6155 9.43039 11.6155 8.53824C11.6155 7.64609 10.8923 6.92285 10.0002 6.92285C9.108 6.92285 8.38477 7.64609 8.38477 8.53824C8.38477 9.43039 9.108 10.1536 10.0002 10.1536Z" fill="white"/>
                  <path d="M12.1539 15L9.99973 12.8462L7.84619 15" stroke="white" stroke-width="1.5" stroke-linecap="square" stroke-linejoin="round"/>
                </svg>` : ``

                let htmlContent = `
                  <div class="individual-post-revenues sm:w-auto mt-2 flex items-center py-2 text-14px rounded-lg border-grey-3 
                              sm:w-auto sm:w-full sm:grid sm:grid-cols-[7fr_4fr_4fr_4fr_4fr_4fr_4fr]"
                      data-post-id = '${variant['post_variant_id']}'
                  >
                    <div class='w-40 flex-shrink-0 sm:w-auto px-2 post-name break-word'>
                      ${variant['name']}
                    </div>
                    <div class='w-24 flex-shrink-0 ml-2 sm:ml-0 sm:w-auto px-1 post-revenue'>$ ${variant['total_price'].toFixed(2)}</div>
                    <div class='w-20 flex-shrink-0 ml-2 sm:ml-0 sm:w-auto px-1 post-like'>-</div>
                    <div class='w-20 flex-shrink-0 ml-2 sm:ml-0 sm:w-auto px-1 post-view'>-</div>
                    <div class='w-15 flex-shrink-0 ml-2 sm:ml-0 sm:w-auto px-1 post-download'>-</div>
                    <div class='w-12 flex-shrink-0 ml-2 sm:ml-0 sm:w-auto px-1 post-save'>-</div>
                    <div class='w-18 flex-shrink-0 ml-2 sm:ml-0 sm:w-auto px-1 post-rating flex'> 
                      <span class="individual-post-collapse-btn inline-block ml-3 flex justify-center items-center"> 
                       ${pv_tag}
                      </span>
                    </div>
                  </div>
                `
                targetContainer.insertAdjacentHTML('beforeend', htmlContent);
              })
            })
        } else {
          revenuesDivs.forEach(div =>{
            div.classList.remove('revenues-hidden');
          })
        }
      } else if (revenuesDivs.length > 0) {
        revenuesDivs.forEach(div =>{
          div.classList.add('revenues-hidden');
        })
      }
    })
  }

  fetchIndividualPostRevenuesDetails(start, end, post_id) { 
    let api_url = `/api/v1/analysis/post_revenues?`
    let params = new URLSearchParams({
      start_at: start,
      end_at: end,
      post_id: post_id,
    })
    return fetch(api_url + params)
      .then(res => res.json())
      .then(json => {
        return json
      })
      .catch(err => console.log(err))
  }
}