const SELECTOR = '[data-slide]'
const SLIDE_CONTAINER = '[data-slide-container]'
const SLIDE_ITEM = '[data-slide-item]'
const ACTION_PREV = '[data-slide-action="prev"]'
const ACTION_NEXT = '[data-slide-action="next"]'
const SLIDE_SECOND = '[data-slide-second]'

const DATA_SLIDE_NUMBER = 'slide'
const DATA_SLIDE_DURATION = 'duration'
const DEFAULT_SLIDE_DURATION = 300

class Slide {
  constructor($dom) {
    this.$dom = $dom
    this.$container = this.$dom.querySelector(SLIDE_CONTAINER)
    this.$prev = this.$dom.querySelector(ACTION_PREV)
    this.$next = this.$dom.querySelector(ACTION_NEXT)

    // data
    this.slideDuration = Number(this.$dom.dataset[DATA_SLIDE_DURATION]) || DEFAULT_SLIDE_DURATION
    this.slide_items = this._getSlideItems()
    this.total = this.slide_items.length
    this.slideItemWidth = this._getSlideItemWidth()
    this.slideNumber = Number(this.$dom.dataset[DATA_SLIDE_NUMBER])
    this.viewNumber = this._getViewNumber()

    // Add touch-related properties
    this.isDragging = false;
    this.startX = 0;
    this.currentTranslate = 0;
    this.prevTranslate = 0;
    this.animationID = null;
    this.startPosition = 0;

    // init
    this.setTransform()
    this.setPosition(0)
    this.bindEvents()
    this.bindTouchEvents();
  }

  setTransform() {
    this.$container.style.transition = `left ${this.slideDuration / 1000}s`
  }

  slide(newPosition) {
    this.setPosition(newPosition)
  }

  setPosition(newPosition) {
    this.position = newPosition
    this.updatePositionView()
    this.updateActionView()
  }

  updatePositionView() {
    this.$container.style.left = this.position * this.slideItemWidth * -1 + 'px'
  }

  updateActionView() {
    this.$prev.classList.remove('hidden')
    this.$next.classList.remove('hidden')

    if (this.position === 0) {
      this.$prev.classList.add('hidden')
    }
    if (this.position + this.viewNumber >= this.total) {
      this.$next.classList.add('hidden')
    }
  }

  bindEvents() {
    this.$prev.addEventListener('click', () => {
      this.slide(this._getPrevPosition())
    })

    this.$next.addEventListener('click', () => {
      this.slide(this._getNextPosition())
    })

    window.addEventListener('resize', () => {
      this.slideItemWidth = this._getSlideItemWidth()
      this.viewNumber = this._getViewNumber()
    })

    if(document.querySelector(SLIDE_SECOND) && window.innerWidth < 768){
      this.slide(this._getNextPosition())
    }

  }

  bindTouchEvents() {
    if (window.innerWidth < 1024) {
      this.$container.addEventListener('touchstart', this.touchStart.bind(this));
      this.$container.addEventListener('touchmove', this.touchMove.bind(this));
      this.$container.addEventListener('touchend', this.touchEnd.bind(this));
    }
    
    window.addEventListener('resize', () => {
      if (window.innerWidth < 1024) {
        this.$container.addEventListener('touchstart', this.touchStart.bind(this));
        this.$container.addEventListener('touchmove', this.touchMove.bind(this));
        this.$container.addEventListener('touchend', this.touchEnd.bind(this));
      } else {
        this.$container.removeEventListener('touchstart', this.touchStart.bind(this));
        this.$container.removeEventListener('touchmove', this.touchMove.bind(this));
        this.$container.removeEventListener('touchend', this.touchEnd.bind(this));
      }
    });
  }

  touchStart(event) {
    this.isDragging = true;
    this.isSwiping = false;
    this.startX = event.touches[0].clientX;
    this.startPosition = this.position;
    this.touchStartTime = new Date().getTime();
    // 停止動畫過渡效果
    this.$container.style.transition = 'none';
  }
  
  touchMove(event) {
    if (!this.isDragging) return;
    
    const currentX = event.touches[0].clientX;
    const diff = currentX - this.startX;
    
    // 只有當滑動距離超過閾值(10px)時才視為滑動行為
    if (Math.abs(diff) > 10) {
      event.preventDefault();

      this.isSwiping = true; // 標記為滑動行為
      
      // 計算新位置並限制在合理範圍內
      const maxPosition = this.total - this.viewNumber;
      const newPosition = this.startPosition - diff / this.slideItemWidth;
      this.currentTranslate = Math.max(0, Math.min(newPosition, maxPosition));
      
      // 設置容器位置
      const translateX = -this.currentTranslate * this.slideItemWidth;
      this.$container.style.left = `${translateX}px`;
    }
  }
  
  touchEnd() {
    if (!this.isDragging) return;
    this.isDragging = false;
    
    // 只有在確認是滑動行為時才執行滑動邏輯
    if (this.isSwiping) {
      // 恢复過渡動畫
      this.$container.style.transition = `left ${this.slideDuration / 1000}s`;
      
      // 計算最终位置（四舍五入到最近的整數位置）
      const finalPosition = Math.round(this.currentTranslate);
      this.setPosition(finalPosition);
      
      // 阻止觸摸結束後的點擊事件（防止滑動後觸發點擊）
      const touchEndTime = new Date().getTime();
      const touchDuration = touchEndTime - this.touchStartTime;
      
      // 為了確保不會干擾正常點擊，我們添加一個事件處理器來阻止接下來的點擊
      if (touchDuration < 300) { // 如果觸摸時間短，可能會觸發點擊
        const clickBlocker = (e) => {
          e.preventDefault();
          e.stopPropagation();
          document.body.removeEventListener('click', clickBlocker, true);
        };
        // 使用捕獲階段監聽點擊事件，確保能在事件冒泡前攔截
        document.body.addEventListener('click', clickBlocker, true);
        // 100毫秒後自動移除這個阻止器
        setTimeout(() => {
          document.body.removeEventListener('click', clickBlocker, true);
        }, 100);
      }
    }
  }

  // private
  _getSlideItems() {
    const $slideItemArray = [...this.$container.querySelectorAll(SLIDE_ITEM)]

    return $slideItemArray.filter(a => a.offsetParent)
  }

  _getSlideItemWidth() {
    const $slideItem = this.slide_items[0]

    if($slideItem){
      return parseFloat(window.getComputedStyle($slideItem).getPropertyValue('width'))
    }else{
      return 0.0
    }
  }

  _getViewNumber() {
    const paddingLeftWidth = parseFloat(window.getComputedStyle(this.$dom).getPropertyValue('padding-left'))
    const paddingRightWidth = parseFloat(window.getComputedStyle(this.$dom).getPropertyValue('padding-right'))
    return (this.$dom.offsetWidth - paddingLeftWidth - paddingRightWidth) / this.slideItemWidth
  }

  _getPrevPosition() {
    const newPosition = this.position - this.slideNumber
    return newPosition < 0 ? 0 : newPosition
  }

  _getNextPosition() {
    const newPosition = this.position + this.slideNumber
    if (newPosition + this.viewNumber >= this.total) {
      return this.total - this.viewNumber
    } else {
      return newPosition
    }
  }
}

function getInitialSlidePosition($dom) {
  // 首先檢查是否直接指定了初始位置
  if ($dom.hasAttribute('data-initial-position')) {
    const position = parseInt($dom.getAttribute('data-initial-position'), 10);
    return position;
  }
  
  // 查找帶有 active 類的導航項
  const activeItem = $dom.querySelector('[data-slide-item] a.active, [data-slide-item].active');
  
  // 如果找到了帶有 active 類的項
  if (activeItem) {
    // 找到包含該鏈接的滑動項
    const slideItem = activeItem.closest('[data-slide-item]');
    // 找到該滑動項在所有滑動項中的索引
    const allItems = $dom.querySelectorAll('[data-slide-item]');
    const itemArray = Array.from(allItems);
    const index = itemArray.indexOf(slideItem);
    // 如果找到了有效索引，返回它
    if (index !== -1) {
      return index;
    }
  }
  
  return 0;
}

export const initSlide = () => {
  if (document.readyState === 'loading') {
    document.addEventListener('DOMContentLoaded', initSlideWhenReady);
  } else {
    initSlideWhenReady();
  }
};

function initSlideWhenReady() {
  const slideInstances = [];
  document.querySelectorAll(SELECTOR).forEach(($dom) => {
    const slideInstance = new Slide($dom);
    $dom.__slideInstance = slideInstance; // Store the instance in the DOM element
    slideInstances.push(slideInstance);
  });
  
  // Use requestAnimationFrame to ensure execution in the next frame
  requestAnimationFrame(() => {
    slideInstances.forEach(instance => {
      const initialPosition = getInitialSlidePosition(instance.$dom);
      instance.setPosition(initialPosition);
    });
  });
}