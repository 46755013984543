import { Controller } from "stimulus"
import Swiper, { EffectCoverflow } from 'swiper';
import 'swiper/css/effect-coverflow';

export default class extends Controller {
  static targets = ['header', 'paidTab', 'freeTab', 'swiper', 'rankingContainer']

  connect() {
    // Swiper 配置
    const swiperOptions = {
      // 基本模組
      modules: [EffectCoverflow],
      // 基本設置
      slidesPerView: 'auto',     // 自動計算可見幻燈片數量
      centeredSlides: true,      // 居中當前幻燈片
      loop: true,                // 循環播放
      // 設置間距，防止擠壓
      spaceBetween: 20,          // 幻燈片之間的間距
      // Coverflow 效果設置
      effect: 'coverflow',
      coverflowEffect: {
        rotate: 35,              // 旋轉角度
        stretch: 0,              // 拉伸
        depth: 100,             // 深度
        modifier: 1,            // 效果修飾符
        slideShadows: true      // 幻燈片陰影
      },
      
      // 響應式斷點設置
      breakpoints: {
        // 移動端
        384: {
          slidesPerView: 'auto',
          coverflowEffect: {
            rotate: 25,
            depth: 50
          }
        },
        // 平板
        768: {
          slidesPerView: 'auto',
          coverflowEffect: {
            rotate: 30,
            depth: 80
          }
        },
        // 桌面
        1280: {
          slidesPerView: 'auto',
          coverflowEffect: {
            rotate: 35,
            depth: 100
          }
        }
      }
    }

    // 初始化所有 Swiper
    this.swipers = this.swiperTargets.map(element => {
      return new Swiper(element, swiperOptions);
    });
  }

  // Tab 切換功能
  switchTab(event) {
    const clickedTab = event.currentTarget;
    const isPaidTab = clickedTab === this.paidTabTarget;

    this.paidTabTarget.classList.toggle('active', isPaidTab);
    this.freeTabTarget.classList.toggle('active', !isPaidTab);

    const allContainers = document.querySelectorAll('[data-home-rank-slider-target="rankingContainer"]');
    allContainers.forEach(container => {
      const type = container.dataset.rankingType;
      if ((type === 'paid' && isPaidTab) || (type === 'free' && !isPaidTab)) {
        container.classList.remove('hidden');
      } else {
        container.classList.add('hidden');
      }
    });
  }

  disconnect() {
    if (this.swipers) {
      this.swipers.forEach(swiper => {
        if (swiper) {
          swiper.destroy(true, true);
        }
      });
      this.swipers = null;
    }
  }
}