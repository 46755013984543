import { Controller } from "stimulus"
import Swiper, { Autoplay } from 'swiper';

// 用在首頁上方的banner
export default class extends Controller {
  static targets = ['swiper']

  swiper = null
  
  connect() {
    this.swiper = new Swiper(this.swiperTarget, {
      modules: [ Autoplay ],
      direction: 'horizontal',
      lazy: {
        loadPrevNext: true,
      },
      loop: true,
      slidesPerView: 1,
      centeredSlides: true,
      autoplay: {
        delay: 4500,
        disableOnInteraction: false,
      }
    });
  }
  disconnect() {
    this.swiper.destroy()
    this.swiper = null
  }
}
